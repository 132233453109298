<template>
  <div class="p-4">
    <DxDataGrid
      :data-source="materiales"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :columnAutoWidth="true"
      :allow-column-resizing="true"
    >
      <DxSearchPanel :visible="false" :width="240" placeholder="Buscar..." />
      <DxPaging :page-size="15" />
      <DxHeaderFilter :visible="true" />
      <DxFilterRow :visible="true">
        <DxOperationDescriptions
          equal="Igual a"
          notEqual="Diferente de"
          startsWith="Empieza con"
          endsWith="Termina con"
          contains="Contiene"
          between="Entre"
          notContains="No contiene"
          lessThanOrEqual="Menor o igual"
          greaterThanOrEqual="Mayor o igual"
          lessThan="Menor que"
          greaterThan="Mayor que"
        />
      </DxFilterRow>
      <DxPager
        :visible="false"
        infoText="Página {0} of {1} ({2} registros)"
        :allowed-page-sizes="pageSizes"
        display-mode="full"
        :show-page-size-selector="true"
        :show-info="true"
        :show-navigation-buttons="true"
      />
      <DxColumn data-field="codmaterial" caption="Código" />
      <DxColumn data-field="observaciones" caption="Detalle" />
    </DxDataGrid>
  </div>
</template>
<script>
import {
  DxOperationDescriptions,
  DxDataGrid,
  DxPaging,
  DxColumn,
  DxSearchPanel,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
} from "devextreme-vue/data-grid";
export default {
  components: {
    DxOperationDescriptions,
    DxDataGrid,
    DxPaging,
    DxColumn,
    DxSearchPanel,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
  },
  props: {
    materiales: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pageSizes: [10, 30, 50, 100],
    };
  },
};
</script>
