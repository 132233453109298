<template>
  <div>
    <DxTabPanel>
      <DxItem title="Materiales instalados" template="instalados" />
      <template #instalados>
        <MaterialList :materiales="aItems.dato_materiales" />
      </template>
      <DxItem title="Materiales retirados" template="retirados" />
      <template #retirados>
        <MaterialList :materiales="aItems.dato_materiales_retirados" />
      </template>
    </DxTabPanel>
  </div>
</template>

<script>
import { DxTabPanel, DxItem } from "devextreme-vue/tab-panel";
import MaterialList from "./MaterialList.vue";

export default {
  components: {
    DxTabPanel,
    DxItem,
    MaterialList,
  },
  props: {
    aItems: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
